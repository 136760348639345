import { PostModel } from '@/app/shared/interface/blog.interface';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrl: './blog-card.component.scss'
})
export class BlogCardComponent {
  @Input() data: PostModel | undefined;
}
