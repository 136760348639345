<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<div class="container-fluid-lg mx-auto">
    <div class="row">
        <div class="col-sm-12 col-xs-12 pb-sm-3 pb-xs-3" [ngClass]="category_sidebar.length ? 'col-md-8' : 'col-md-12'">
            <div class="bg-white rounded-3 pb-5">
                <app-blog-pin-list></app-blog-pin-list>
                <hr/>
                <div *ngIf="posts.length">
                    <ng-container *ngFor="let post of posts; let i = index">
                        <app-blog-card [data]="post"></app-blog-card>
                        <hr *ngIf="i !== posts.length - 1">
                    </ng-container>
                </div>
                <nav class="custome-pagination">
                    <app-collection-paginate></app-collection-paginate>
                </nav>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12" *ngIf="category_sidebar.length">
            <div class="bg-white rounded-3">
                <div *ngFor="let cat of category_sidebar" class="mb-2">
                    <app-blog-category-list [category_slug]="cat.slug" [category_title]="cat.categoryName"></app-blog-category-list>
                </div>
            </div>
        </div>
    </div>
</div>