import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PostCategory, PostModel, PostPagingModel } from "../interface/blog.interface";
import { environment } from "@/environments/environment";
import { Observable } from "rxjs";
import { RetriveUserModel } from "@/app/shared/interface/product.interface";

@Injectable({ providedIn: 'root' })
export class BlogService {
    constructor(private http: HttpClient) {

    }
    private header = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    getBlogBySlug(slug: string) {
        return this.http.get<PostModel>(`${environment.baseURL}blog/${slug}`, this.header);
    }
    getPinnedPosts(): Observable<PostModel[]> {
        return this.http.get<PostModel[]>(`${environment.baseURL}blog/pinnedPosts`, this.header);
    }
    getPinnedCategories(): Observable<PostCategory[]> {
        return this.http.get<PostCategory[]>(`${environment.baseURL}blog/pinnedCategegoryPosts`, this.header);
    }
    getPostByCategory(slug: string, limit: number = 5): Observable<PostModel[]> {
        let params = new HttpParams();
        params = params.set('limit', limit ?? 10);
        let options = {
            ...this.header,
            params: params,
        };
        return this.http.get<PostModel[]>(`${environment.baseURL}blog/get-post-by-category/${slug}`, options);
    }
    getBlogs(paging: RetriveUserModel): Observable<PostPagingModel> {
        let params = new HttpParams();
        params = params.set('limit', paging.limit ?? 10)
            .set('offset', paging.offset ?? 0);

        if (paging.searchTerm) {
            params = params.set('search', paging.searchTerm);
        }

        let options = {
            ...this.header,
            params: params,
        };
        return this.http.get<PostPagingModel>(`${environment.baseURL}blog/get-posts`, options);
    }

    getStylePost(): Observable<any> {
        return this.http.get(`${environment.baseURL}blog/get-style-post`, {
            responseType: 'text'
        });
    }
}