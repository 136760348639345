<div class="card mb-3 px-3" *ngIf="data">
    <div class="row g-0">
        <div class="col-md-4">
            <a [routerLink]="['/', data.slug]">
                <img [src]="data.postThumbnailUrl"
                class="img-fluid rounded rounded-3" [alt]="data.postTitle">
            </a>
        </div>
        <div class="col-md-8">
            <div class="card-body">
                <h5 class="card-title" [title]="data.postTitle">
                    <a [routerLink]="['/', data.slug]">{{data.postTitle}}</a></h5>
                <p class="card-text"><small class="text-muted"><i class="ri-time-line"></i> {{data.postCreatedAt | date : "dd/MM/yyyy" }}</small></p>
            </div>
        </div>
    </div>
</div>