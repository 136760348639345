import { BlogModel, PostModel } from '@/app/shared/interface/blog.interface';
import { BlogService } from '@/app/shared/services/blog.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blog-category-list',
  templateUrl: './blog-category-list.component.html',
  styleUrl: './blog-category-list.component.scss'
})
export class BlogCategoryListComponent {
  @Input() category_slug: string;
  @Input() category_title: string;

  posts: PostModel[] = [];

  constructor(private blogService: BlogService) {

  }

  ngOnInit() {
    this.blogService.getPostByCategory(this.category_slug).subscribe({
      next: (data: PostModel[]) => {
        if (data) {
          this.posts = data;
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }
}
