import { PostModel } from '@/app/shared/interface/blog.interface';
import { BlogService } from '@/app/shared/services/blog.service';
import { Component } from '@angular/core';
import { SummaryPipe } from '@/app/shared/pipe/summary.pipe';

@Component({
  selector: 'app-blog-pin-list',
  templateUrl: './blog-pin-list.component.html',
  styleUrl: './blog-pin-list.component.scss'
})
export class BlogPinListComponent {
  posts: PostModel[] | [];
  topPost: PostModel | undefined;
  constructor(private blogService: BlogService) {

  }

  ngOnInit(){
    this.getPinnedPosts();
  }

  getPinnedPosts() {
    this.blogService.getPinnedPosts().subscribe({
      next: (data: PostModel[]) => {
        if (data) {
          this.posts = data;
          this.topPost = this.posts.shift();
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }
}
