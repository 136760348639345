<div class="row p-3 pt-4">
    <div class="col top-post pinned-post" *ngIf="topPost">
        <div class="card">
          <a [routerLink]="['/', topPost.slug]">
            <img [src]="topPost.postThumbnailUrl"  class="card-img-top rounded rounded-3" [alt]="topPost.postTitle">
          </a>
            <div class="card-body px-0">
              <h5 class="card-title fw-bold entry-title">
                <a [routerLink]="['/', topPost.slug]">{{topPost.postTitle}}</a></h5>
              <p class="card-text">{{topPost.postDescription | summary: 150}}</p>
            </div>
          </div>
    </div>
    <div class="col">
        <app-blog-card *ngFor="let post of posts" [data]="post" ></app-blog-card>
    </div>
</div>